<template>
    <div class="avatar">
        <div
            class="cropper"
        >
            <label
                class="cropper__label"
                :class="{ 'drag' : dragMode }"
                for="files_upload2"
                @dragenter.prevent
                @drop.prevent="drop"
                @dragover.prevent="dragover"
                @dragleave.prevent="dragleave"
            >
                <template v-if="!!avatarUrl || !!avatarFallback">
                    <avatar
                        :avatar-fallback="avatarFallback"
                        :avatar-url="avatarUrl"
                        class="cropper__label__avatar"
                    />
                    <span
                        class="cropper__label__text"
                    >
                        Change the profile picture
                    </span>
                </template>
                <template v-else>
                    <account-circle
                        class="cropper__label__icon"
                        :width="50"
                        :height="50"
                    />
                    <span
                        class="cropper__label__text"
                    >
                        Upload the profile picture
                    </span>
                </template>
            </label>
            <input
                id="files_upload2"
                ref="input"
                name="uploaded_image"
                type="file"
                accept="image/*"
                class="cropper__uploader"
                multiple
                @change="setImage($event)"
            >
            <croper-modal
                v-if="showModal"
                title="Crop your photo"
                name="avatar"
                :circle-mode="true"
                @rotate="rotate"
                @close="closeModal"
                @submit="submitModal"
            >
                <template>
                    <vue-cropper
                        v-if="image"
                        ref="cropper"
                        :src="image"
                        alt="Source Image"
                        :aspect-ratio="1"
                        class="cropper-plugin"
                    />
                </template>
            </croper-modal>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '@/helpers/event-bus';
// import Api from '@/helpers/api/index.js'
import AccountCircle from 'mdi-vue/AccountCircle.vue'
import CroperModal from '@/components/modals/CroperModal'
import VueCropper from 'vue-cropperjs'
import Compressor from 'compressorjs';
import Avatar from '@/components/common/Avatar.vue'
import 'cropperjs/dist/cropper.css'

import {
    ACTION_WRITER_UPDATE_AVATAR
} from '@/store/modules/writer/action-types';

const {
    mapActions: mapWriterActions
} = createNamespacedHelpers('writer');

export default {
    name: 'AvatarLoader',
    components: {
        CroperModal,
        VueCropper,
        AccountCircle,
        Avatar
    },
    props: {
        avatar: {
            type: String,
            default: ''
        },
        avatarFallback: {
            type: String,
            required: false
        },
        avatarUrl: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            image: null,
            cropedImage: '',
            dragMode: false,
            fileName: '',
            loadError: false,
            showModal: false
        }
    },
    created() {
        this.loadError = false
        this.cropedImage = this.avatar
    },
    methods: {
        ...mapWriterActions({
            ACTION_WRITER_UPDATE_AVATAR
        }),
        sendImage(name) {
            this.loadError = false
            if (name !== 'avatar') { return }
            const $vm = this
            this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
                new Compressor(blob, {
                    quality: 1,
                    async success(result) {
                        if (result.size / 1024 / 1024 > 8) {
                            eventBus.$emit('showSnackBar', 'File size exceeds 8Mb', 'error');
                            throw new Error('Error')
                        }
                        const formData = new FormData();
                        formData.append('file', result, $vm.fileName);
                        await $vm.loadAvatar(formData)
                        // $vm.hideModal(name)
                    },
                    error(err) {
                        console.error(err.message);
                    }
                });
            });
        },
        async loadAvatar(formData) {
            try {
                await this[ACTION_WRITER_UPDATE_AVATAR]({ formData, callback: this.onUploadProgress })
                this.$emit('finish')
                eventBus.$emit('showSnackBar', 'Your avatar has been updated', 'success');
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        },
        onUploadProgress(progressEvent) {
            this.uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        },
        setImage(e) {
            this.loadImage(e.target.files[0])
        },
        removeAvatar() {
            this.cropedImage = null
        },
        drop(e) {
            this.$refs.input.files = e.dataTransfer.files
            this.dragMode = false
            this.loadImage(e.dataTransfer.files[0])
        },
        dragover() {
            if (!this.dragMode) {
                this.dragMode = true
            }
        },
        dragleave() {
            this.dragMode = false
        },
        loadImage(file) {
            this.fileName = file.name
            const reader = new FileReader()
            reader.onload = (event) => {
                this.image = event.target.result
                this.showModal = true
            };
            reader.readAsDataURL(file)
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        },
        closeModal() {
            this.showModal = false
        },
        submitModal() {
            this.sendImage('avatar')
            this.cropedImage = this.image
            this.showModal = false
        }
    }
}
</script>

<style lang="scss">
.cropper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #D3D9E2;
    &__label {
        cursor: pointer;
        height: 105px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: transparent;
        transition: background-color .5s ease;
        &.drag{
            background-color: #ccc;
        }
        &__text {
            color: #1F2939;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
        }
        &__icon {
            color: #D3D9E2;
            width: 50px;
            height: 50px;
            margin-bottom: 10px;
        }
        &__avatar {
            margin-bottom: 10px;
        }
    }
    &__uploader {
        display: none;
    }
}
.cropper-crop-box {
    border-radius: 50%;
}
.cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    border-radius: 50%;
    outline: inherit !important;
}
.cropper-face {
    background-color:inherit !important;
}
.cropper-dashed, .cropper-point.point-se, .cropper-point.point-sw, .cropper-point.point-nw,   .cropper-point.point-ne, .cropper-line {
    display:none !important;
}
.avatar {
    width: 100%;
    display: flex;
    &-wrapper {
        display: flex;
        align-items: center;
    }
    &-delete {
        margin-left: 20px;
    }
}

</style>
