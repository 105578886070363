<template>
    <div
        class="confirm-modal"
    >
        <div class="confirm-modal__dialog">
            <header class="confirm-modal__header">
                <h2>{{ title }}</h2>
                <span
                    class="confirm-modal__close"
                    @click="close()"
                />
            </header>
            <section class="confirm-modal__content">
                <slot />
            </section>
            <footer>
                <div class="confirm-modal__two-button-block">
                    <button
                        class="confirm-modal__two-button confirm-modal__two-button--gray"
                        @click.prevent="close()"
                    >
                        cancel
                    </button>
                    <button
                        class="confirm-modal__two-button confirm-modal__two-button--main"
                        @click.prevent="submit()"
                    >
                        ok
                    </button>
                    <custom-button
                        default
                        type="button"
                        width="40px"
                        classes="bg-none"
                        @on-btn-click="rotate(90)"
                    >
                        <rotate-right />
                    </custom-button>
                    <custom-button
                        default
                        type="button"
                        width="40px"
                        classes="bg-none"
                        @on-btn-click="rotate(-90)"
                    >
                        <rotate-left />
                    </custom-button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import RotateLeft from 'mdi-vue/RotateLeft'
import RotateRight from 'mdi-vue/RotateRight'

export default {
    name: 'CroperModal',
    components: {
        RotateLeft,
        RotateRight
    },
    props: {
        title: String,
        content: String,
        name: String,
        circleMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialog: false
        }
    },
    methods: {
        rotate(deg) {
            this.$emit('rotate', deg)
        },
        close() {
            this.$emit('close')
        },
        submit() {
            this.$emit('submit')
        }
    }
}
</script>

<style>
.avatar-cropper {
    max-height: 60vh;
}

.avatar-cropper .cropper-view-box {
    height: 100%;
    outline: 1px solid #39f;
    outline-color: rgba(51,153,255,.75);
    overflow: hidden;
    width: 100%;
}

.circle-image .avatar-cropper .cropper-view-box {
    border-radius: 50%!important;
}

.cropper-wrap-box {
    background: #808080;
}
.confirm-modal__two-button-block > button{
    margin-right: 10px;
}
</style>
